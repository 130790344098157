import React from "react";
import { Typography, Paper, Divider } from "@material-ui/core";
import { startOfWeek, endOfWeek } from 'date-fns'
import { sumBy } from 'lodash';

const StravaThisWeekStats = ({ activities }) => {
	const numberFormat = (n) => {
		return n
			.toFixed(2)
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

  const curr = new Date();
  const startDate = startOfWeek(curr, {weekStartsOn: 1});
  const endDate = endOfWeek(curr, {weekStartsOn: 1});  

  const activitiesInWeek = activities.filter((activity) => new Date(activity.start_date_local) >= startDate && new Date(activity.start_date_local) <= endDate);

  const runsInWeek = activitiesInWeek.filter(x => x.type === 'Run');
  const ridesInWeek = activitiesInWeek.filter(x => x.type === 'Ride');
  const zwiftInWeek = activitiesInWeek.filter(x => x.type === 'VirtualRide');

	return (
		<Paper style={{ width: 300, margin: 15, textAlign: "center", padding: 10}}>
			<Typography style={{ marginBottom: 10 }} variant='h6'> 
				Weekly Stats
			</Typography>
			<Typography variant='body1'>
				{runsInWeek.length}x Run @ {" "}
				{numberFormat(sumBy(runsInWeek, 'distance')/1000.00)}km
			</Typography>
			<Divider style={{ margin: 10 }} />
			<Typography variant='body1'>
				{ridesInWeek.length}x Ride @ {" "}
        {numberFormat(sumBy(ridesInWeek, 'distance')/1000.00)}km

			</Typography>
      <Divider style={{ margin: 10 }} />
			<Typography variant='body1'>
				{zwiftInWeek.length}x Zwift @ {" "}
        {numberFormat(sumBy(zwiftInWeek, 'distance')/1000.00)}km

			</Typography>
		</Paper>
	);
};

export default StravaThisWeekStats;
