import React from 'react';

export const AppContext = React.createContext({
    postcode: '',
    darkMode: false,
    toggleDarkMode: () => {},
    isDrawerOpen: false,
    toggleDrawer: () => {},
    updatePostcode: (postcode) => {}
  });
