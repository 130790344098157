import React from "react";
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import MediumPaper from "../hoc/MediumPaper";

const CalorieEntriesDisplay = (props) => {

	const {goal, entries, setEntries} = props;

  const deleteEntry = (i) => {
		let newEntries = [...entries];
		newEntries.splice(i, 1);
		setEntries(newEntries);
  } 

	return (
    <>
      <MediumPaper>
				<Typography variant='h4'>
					{entries.reduce((prev, cur) => prev + cur.calories, 0)} kcal
				</Typography>
				<Typography variant='h6'>
					{goal - entries.reduce((prev, cur) => prev + cur.calories, 0)}{" "}
					remaining
				</Typography>
				<Table aria-label="calories table">
					<TableHead>
						<TableRow>							
							<TableCell style={{fontWeight: 'bold'}}>Description</TableCell>
							<TableCell style={{fontWeight: 'bold'}} align="right">Calories</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
					{entries.map((x, i) => {
						return (
							<TableRow hover key={i}>
								<TableCell>{x.text}</TableCell>
								<TableCell  align="right">{x.calories}</TableCell>
								<TableCell align="right"><Button variant="contained" color="secondary" onClick={() => deleteEntry(i)}>Delete</Button></TableCell>
							</TableRow>
						);
					})}
					</TableBody>
				</Table>			
			</MediumPaper>			
		</>
  )
};

export default CalorieEntriesDisplay;
