import React, { Component } from 'react';
import { TimerDisplay } from '../components';

class Timer extends Component {

    constructor(props) {
        super(props);
        this.state = { date: new Date() }
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({ date: new Date() });
    }

    render() {
       return <TimerDisplay date={this.state.date} />
    }

}


export default Timer;