import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {HomePage, AccountPage, StripePage} from './components/pages';
import {LoginPage, CalorieCounterPage} from './containers/pages';
import AuthenticateRoute from './hoc/AuthenticateRoute';
import StravaCallback from './strava/containers/StravaCallback';
import {StravaPage} from './strava/containers/StravaPage';
import {StravaActivityPage} from './strava/containers/StravaActivityPage';

const MainRoutes = () => {

  return (
    <Switch>
      <AuthenticateRoute path='/account' component={AccountPage} />
      <AuthenticateRoute path='/stripe' component={StripePage} />

      <Route path='/login' component={LoginPage} />      
      <Route path='/callback/strava' component={StravaCallback} />      
      <Route path='/auth/strava' render={() => {window.location = `https://www.strava.com/oauth/authorize?client_id=14806&redirect_uri=${window.location.origin}/callback/strava&response_type=code&scope=read,activity:read_all,profile:read_all,read_all`}} /> 
      <Route path='/strava/activity/:activityId' component={StravaActivityPage} />      

      <Route path='/strava' component={StravaPage} />
      <Route path='/calorie-counter' component={CalorieCounterPage} />

      <Route path='/' component={HomePage} />
      
    </Switch>
  )
}

export default MainRoutes;