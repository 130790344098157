import React from 'react';
import { Paper, makeStyles, Typography} from '@material-ui/core'
import ThemedPaper from '../../hoc/ThemedPaper';


const useStyles = makeStyles((theme) => ({
  root: {
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
   flexWrap: 'wrap',
   margin: 10
  },
  stats: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  gear: {
    width: 200,
    margin: 15,
    textAlign: 'center',
    padding: '10px'
  },
  primary: {
    backgroundColor: theme.palette.type === 'dark' ? 'inherit' : '#AFF8DB'
  }
}));

const StravaGearStats = ({gear, title}) => {

  const classes = useStyles();

  return (
    <ThemedPaper className={classes.root}>
      <Typography paragraph variant='h5'>{title}</Typography>      
      <div className={classes.stats}>
        {gear
        .sort((a, b) => a.distance < b.distance ? 1 : -1)
        .map(x => {

          const className = [classes.gear, x.primary ? classes.primary : null];

          return (
            <Paper className={className.join(' ')} key={x.id}> 
              {x.primary && <Typography variant='body1'>(PRIMARY)</Typography>}
              <Typography variant='h6'>{x.name}</Typography>
              <Typography variant='body1'>({(x.distance/1000).toFixed(2)} km)</Typography>
            </Paper>
          )
        })}
      </div>   
    </ThemedPaper>
  )
};

export default StravaGearStats;