import React, { useContext, useState } from 'react';
import SmallPaper from '../hoc/SmallPaper';
import {RootContext} from '../context';
import { Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    button: {
        margin: '10px 5px'
    },
});

export const UserName = () => {

    const context = useContext(RootContext);
    const classes = useStyles();

    const [isEditMode, setIsEditMode] = useState(false);
    const [username, setUsername] = useState(context.authBody.username);

    const handleSubmit = () => {
        setIsEditMode(false);
        context.setAuthBody({...context.authBody, username});
    }

    const handleKeydown = (e) => {
        if (e.keyCode === 13) {
            handleSubmit();
        }
    }

    const UpdateElement = (
        <>
            <TextField onChange={(e) => setUsername(e.target.value)} variant='outlined' label="Who goes there?" onKeyDown={handleKeydown} fullWidth style={{ flexGrow: 1 }} />
            <Button className={classes.button} variant='contained' color='primary' onClick={handleSubmit}>Save</Button>
        </>
    );

    const ShowSavedElement = (
        <>
            <Typography variant='h6'>Hey {context.authBody.username}</Typography>
            <Button className={classes.button} variant='contained' color='primary' onClick={() => setIsEditMode(true)}>
                Update Username
            </Button>
        </>
    )   

    return (
        <SmallPaper>
            {context.authBody.username === '' || isEditMode ? UpdateElement : ShowSavedElement}
        </SmallPaper>
    )
}
