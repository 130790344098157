import React, { useRef } from "react";
import { Button, TextField } from "@material-ui/core";
import SmallPaper from "../hoc/SmallPaper";

const CalorieEntryAdd = (props) => {

	const {entries, setEntries} = props;

	const descriptionRef = useRef("");
	const caloriesRef = useRef("");

	const handleSubmit = () => {
		let newEntries = [...entries];
		newEntries.push({
			text: descriptionRef.current.value,
			calories: Number(caloriesRef.current.value),
		});

		setEntries(newEntries);
		resetForm();
	};

	const handleKeydown = (e) => {
		if (e.keyCode === 13) {
			handleSubmit();
		}
	};

	const resetForm = () => {
    caloriesRef.current.value = "";

    descriptionRef.current.value = "";
    descriptionRef.current.focus();
	};

	return (
    <>
      <SmallPaper elevation={3}>
        <form>
          <TextField
            inputRef={descriptionRef}
            variant='outlined'
            label='Description'
            fullWidth
            style={{ paddingBottom: 10 }}
          />
          <TextField
            inputRef={caloriesRef}
            variant='outlined'
            label='Calories'
            type='number'
            fullWidth
            onKeyDown={handleKeydown}
            style={{ paddingBottom: 10 }}
          />
          <Button
            onClick={resetForm}
            variant='contained'
            color='secondary'
            style={{ marginRight: 10 }}
          >
            Reset
          </Button>
          <Button onClick={handleSubmit} variant='contained' color='primary'>
            Add
          </Button>
        </form>
      </SmallPaper>
			
		</>
  )
};

export default CalorieEntryAdd;
