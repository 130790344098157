import React, { useState, useEffect } from "react";
import { CatFactDisplay } from "../components";
import axios from 'axios';

const CatFact = (props) => {
	const [state, setState] = useState({
		isLoaded: false,
		error: null,
		fact: "",
	});

	useEffect(() => {
		callApi();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const refreshHandler = () => {
		callApi();
	};

	const callApi = () => {
		setState({ isLoaded: false, error: null, fact: "" });

		axios.get('https://cat-fact.herokuapp.com/facts/random')
			.then(
				res => {
					setState({ ...state, isLoaded: true, fact: res.data.text })
				}
			)
			.catch(
				error => setState({ ...state, error, isLoaded: true })
			)
	};

	return <CatFactDisplay {...state} refresh={refreshHandler} />;
};

export default CatFact;
