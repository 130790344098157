import React from 'react';
import {Typography, Paper, makeStyles} from '@material-ui/core'
import {secondsToHms} from '../../helpers/TimeHelpers';

const useStyles = makeStyles({
  root: {
      margin: 15,
      textAlign: 'center',
      padding: '10px'
  }
});

export default ({split, time, distance, measure}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>    
      <Typography style={{paddingTop: 10}} variant='h6'>Split {split}</Typography>
      <Typography variant='body1'>{(distance/1000).toFixed(2)} {measure} </Typography>
      <Typography variant='body1'>{secondsToHms(time)} </Typography>
    </Paper>
  )
 
}
 

