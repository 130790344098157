import React from 'react';
import {Typography, Paper, Divider} from '@material-ui/core'

const numberFormat = (n) => {
  return n.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default (props) => (
  <Paper style={{width: 200, margin: 15, textAlign: 'center', padding: 10}}>    
    <Typography style={{marginBottom: 10}} variant='h6'>{props.title}</Typography>
    <Typography variant='body1'>{props.run.count} Run(s) at a total distance of {numberFormat(props.run.distance)} {props.measure} </Typography>
    <Divider style={{margin: 10}} />
    <Typography variant='body1'>{props.ride.count} Ride(s) at a total distance of {numberFormat(props.ride.distance)} {props.measure} </Typography>
  </Paper>

)