import React, { useContext } from "react";
import {
	SwipeableDrawer,
	makeStyles,
	IconButton,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import HomeIcon from "@material-ui/icons/Home";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import { AppContext } from "../context/AppContext";
import { Link } from "react-router-dom";
import { RootContext } from "../context";

const useStyles = makeStyles((theme) => ({
	drawerHeader: {
		display: "flex",
		width: 250,
		alignItems: "center",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: "flex-end",
	},
}));

const NavDrawer = () => {
	const context = useContext(AppContext);
	const rootContext = useContext(RootContext);
	const classes = useStyles();

	return (
		<SwipeableDrawer
			transitionDuration={500}
			variant='temporary'
			open={context.isDrawerOpen}
			onClose={context.toggleDrawer}
			onOpen={context.toggleDrawer}
			width='100%'
		>
			<div className={classes.drawerHeader}>
				<IconButton
					edge='start'
					className={classes.menuButton}
					color='inherit'
					aria-label='menu'
					onClick={context.toggleDrawer}
				>
					<ChevronLeftIcon />
				</IconButton>
			</div>
			<Divider />
			<List>
				{/* {["Home", "Starred", "Send email", "Drafts"].map((text, index) => (
					<ListItem button key={text}>
						<ListItemIcon>
							{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
						</ListItemIcon>
						<ListItemText primary={text} />
					</ListItem>
				))} */}
				<ListItem button component={Link} to='/' onClick={context.toggleDrawer}>
					<ListItemIcon>
						<HomeIcon />
					</ListItemIcon>
					<ListItemText primary='Home' />
				</ListItem>  
        <ListItem
						button
						component={Link}
						to='/calorie-counter'
						onClick={context.toggleDrawer}
					>
						<ListItemIcon>
							<WhatshotIcon />
						</ListItemIcon>
						<ListItemText primary='Calorie Counter' />
					</ListItem>      
				<ListItem
						button
						component={Link}
						to='/strava'
						onClick={context.toggleDrawer}
					>
						<ListItemIcon>
							<DirectionsRunIcon />
						</ListItemIcon>
						<ListItemText primary='Strava' />
					</ListItem>
			</List>
			<Divider />
			{!rootContext.authenticated ? null : 
			<List>			 
					<ListItem
						button
						component={Link}
						to='/account'
						onClick={context.toggleDrawer}
					>
						<ListItemIcon>
							<AccountCircleIcon />
						</ListItemIcon>
						<ListItemText primary='Account' />
					</ListItem>
			</List>}
		</SwipeableDrawer>
	);
};

export default NavDrawer;
