export const secondsToHms = (d, shortened = true) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var hDisplay = h > 0 
    ? h + (h === 1 
      ? shortened 
        ? " hr " : " hour " 
      : shortened 
        ? " hrs " : " hours ") 
      : "";

  var mDisplay = m > 0 
    ? m + (m === 1 
      ? shortened 
        ? " min " : " minute " 
      : shortened 
        ? " mins " : " minutes ") 
    : "";

  var sDisplay = s > 0 
    ? s + (s === 1 
      ? shortened 
        ? "s"   : " second" 
      : shortened 
        ? "s" : " seconds") 
    : "";
  return hDisplay + mDisplay + sDisplay; 
}

