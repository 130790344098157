import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { RootContext } from '../context/RootContext';

export default ({  ...routeProps }) => {
  const { authenticated } = useContext(RootContext);
  
  return authenticated 
    ? <Route {...routeProps} /> 
    : <Redirect to='/login' />

};