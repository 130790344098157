import React from 'react';
import { useLocalStorage } from '../common/useLocalStorage';

export const RootContext = React.createContext({
  authenticated: false,
  setAuthenticated: () => {}
});

export default ({children}) => {
  const [authenticated, setAuthenticated] = useLocalStorage('auth', false);
  const [authBody, setAuthBody] = useLocalStorage('authBody', {username: ''});

  const defaultContext = {
    authenticated,
    setAuthenticated,
    authBody,
    setAuthBody
  };

  return (
    <RootContext.Provider value={defaultContext}>
      {children}
    </RootContext.Provider>
  );
}