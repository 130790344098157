import axios from 'axios';

const getAuthHeader = (accessToken) => {
  return {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
}

const StravaService = { 

  updateRefreshToken : (refreshToken) => {
    return axios.post(`https://www.strava.com/oauth/token?client_id=14806&client_secret=1eb6fb15579a1a0b83b5d6daac05d9877a94c497&grant_type=refresh_token&refresh_token=${refreshToken}`);
  },

  getAthlete: (accessToken) => {         
    return axios.get("https://www.strava.com/api/v3/athlete", getAuthHeader(accessToken));
  },

  getActivities: (accessToken, page) => {
    return axios.get(`https://www.strava.com/api/v3/athlete/activities?per_page=25&page=${page}`, getAuthHeader(accessToken));
  },

  getActivity: (accessToken, activityId) => {
    return axios.get(`https://www.strava.com/api/v3/activities/${activityId}`, getAuthHeader(accessToken));
  }
}



export default StravaService;