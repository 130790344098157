import React, { useState } from 'react';
import './App.css';
import NavBar from './components/NavBar';
import RootContext from './context/RootContext';
import { createMuiTheme, Paper, ThemeProvider, makeStyles } from '@material-ui/core';
import CssBaseline from "@material-ui/core/CssBaseline";
import {useLocalStorage} from './common/useLocalStorage';
import blue from '@material-ui/core/colors/blue'
import MainRoutes from './MainRoutes';
import {withRouter} from 'react-router-dom';
import {AppContext} from './context/AppContext';
import StravaContext from './context/StravaContext';

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
  },
  typography: {
    fontFamily: 'Titillium Web, sans-serif'
  }
});

const lightTheme = createMuiTheme({
  palette: {
    primary: blue
  },
  typography: {
    fontFamily: 'Titillium Web, sans-serif'
  }
});

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    margin: 'auto',
    alignContent: 'center',
    
    padding: 10,
    [theme.breakpoints.up('lg')]: {
      width: '60%'
    }
  } 
}));

const App = () => {

  const classes = useStyles();

  const [darkMode, setDarkMode] = useLocalStorage('darkMode', false);
  const [postcode, setPostcode] = useLocalStorage('postcode', '');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

   const toggleDrawer = () => {
    setIsDrawerOpen(prev => !prev);
  }

  const toggleDarkMode = () => {
    setDarkMode(prev => !prev);
  }

  const updatePostcode = (newPostcode) => {
    setPostcode(newPostcode);
  }

  return (

    <RootContext>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <CssBaseline />
          <AppContext.Provider value={{postcode, darkMode, isDrawerOpen, toggleDrawer, updatePostcode, toggleDarkMode }}>
            <NavBar />
            <Paper className={classes.content}>
              <StravaContext>
                <MainRoutes />  
              </StravaContext>
            </Paper>   
          </AppContext.Provider>          
      </ThemeProvider>
    </RootContext>

  );
}



export default withRouter(App);
