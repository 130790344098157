import React from "react";
import { useLocalStorage } from "../../common/useLocalStorage";
import CalorieGoal from "../../components/CalorieGoal";
import CalorieEntryAdd from "../../components/CalorieEntryAdd";
import CaloriesEntriesDisplay from "../../components/CalorieEntriesDisplay";

export const CalorieCounterPage = () => {

	const [entries, setEntries] = useLocalStorage("cc-entries", []);
  const [goal, setGoal] = useLocalStorage("cc-goal", 2000);
  
	return (
		<>
      <CalorieGoal goal={goal} setGoal={setGoal} />   
			<CalorieEntryAdd entries={entries} setEntries={setEntries} />
			<CaloriesEntriesDisplay goal={goal} entries={entries} setEntries={setEntries} />
		</>
	);
};
