import React, {useState, useEffect} from 'react';
import {PaymentRequestButtonElement, useStripe} from '@stripe/react-stripe-js';

export const StripePaymentRequest = ({clientSecret}) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {

    if (clientSecret !== null){
      if (stripe) {
        const pr = stripe.paymentRequest({
          country: 'GB',
          currency: 'gbp',
          total: {
            label: 'Checkout Total',
            amount: 10800,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });
  
        // Check the availability of the Payment Request API.
        pr.canMakePayment().then(result => {
  
          console.log('canMakePayment', result);
  
          if (result) {
            setPaymentRequest(pr);
          }
        });
      }
    }

    
  }, [clientSecret, stripe]);



  if (paymentRequest) {

    paymentRequest.on('paymentmethod', async (ev) => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      const {error: confirmError} = await stripe.confirmCardPayment(
        clientSecret,
        {payment_method: ev.paymentMethod.id},
        {handleActions: false}
      );
    
      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete('fail');
        alert('Payment Failed');
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success');
        alert('Payment Success');

        // Let Stripe.js handle the rest of the payment flow.
        // eslint-disable-next-line no-unused-vars
        const {error, paymentIntent} = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          alert('Payment Failed - 2');
        } else {
          alert('Payment Success - 2');
          
        }
      }
    });

    return <PaymentRequestButtonElement options={{paymentRequest}} />
  }

  // Use a traditional checkout form.
return <p>Mobile Payment Button Not Available</p>
}