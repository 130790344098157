import React from "react";
import ThemedPaper from "../../hoc/ThemedPaper";
import StravaActivitySegment from "./StravaActivitySegment";
import {Divider, makeStyles, Typography} from '@material-ui/core';


const useStyles = makeStyles({
	segment: {
		display: "flex", 
		flexWrap: "wrap", 
		justifyContent: "center",
		marginBottom: 10,
  },
	header:{ 
		width: '100%',
		textAlign: 'center',
		textDecoration: 'underline'
	}
});

export default ({ segments }) => {

  const classes = useStyles();

	const firstPlace = segments.filter(x => x.pr_rank === 1);
	const secondPlace = segments.filter(x => x.pr_rank === 2);
	const thirdPlace = segments.filter(x => x.pr_rank === 3);
 
  return (
		<ThemedPaper
			elevation={3}
			style={{ marginTop: 10, backgroundColor: "lightgrey", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
		>
			<div className={classes.segment}>			
				<Typography variant='h5' className={classes.header}>PRs</Typography>
				{firstPlace.map((x) => {
					return (
						<StravaActivitySegment
							key={x.id}
							name={x.name}
							url={'https://www.strava.com/segments/' + x.segment.id}
							distance = {x.distance}
						/>
					);
				})}
			</div>
			<Divider />
			<div className={classes.segment}>	
				<Typography variant='h5' className={classes.header}>2nd Place</Typography>
				{secondPlace.map((x) => {
					return (
						<StravaActivitySegment
							key={x.id}
							name={x.name}
							url={'https://www.strava.com/segments/' + x.segment.id}
							distance = {x.distance}

						/>
					);
				})}
			</div>
			<Divider />
			<div className={classes.segment}>					
				<Typography variant='h5' className={classes.header}>3rd Place</Typography>
				{thirdPlace.map((x) => {
					return (
						<StravaActivitySegment
							key={x.id}
							name={x.name}
							url={'https://www.strava.com/segments/' + x.segment.id}
							distance = {x.distance}

						/>
					);
				})}
			</div>
		</ThemedPaper>
	);
};
