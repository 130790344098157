import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import CartesianGrid from "recharts/lib/cartesian/CartesianGrid";

const StravaActivityChart = ({ data }) => {
  const [mappedData, setMappedData] = useState([]);

  useEffect(() => {
    const newData = data
      .filter((x) => x.distance > 0)
      .map(({ id, distance, type, name, start_date_local }) => ({
        id,
        distance: +(distance / 1000).toFixed(2),
        type,
        distanceString: (distance / 1000).toFixed(2) + "km",
        name, 
        start_date_local
      }))
      .slice(0, 25)
      .reverse();

    setMappedData(newData);
  }, [data]);


  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {

      return (
        <div style={{backgroundColor: '#FFFFFF', padding: '10px', borderRadius: '10px'}} >
          <p style={{fontWeight: 'bold'}}>{payload[0].payload.name}</p>
          <p>{payload[0].payload.distanceString}</p>
          <p>{payload[0].payload.start_date_local}</p>
        </div>
      );
    }
  
    return null;
  };


  return (
    <ResponsiveContainer width='90%' height={400}>
      <BarChart data={mappedData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey='type' type='category' />
        <YAxis type='number' unit='km' />
        <Tooltip content={CustomTooltip} />
        <Bar dataKey='distance'>
          {mappedData.map((entry) => {
            let color;

            switch (entry.type) {
              case "Ride":
                color = "#85E3FF";
                break;
              case "Run":
                color = "#AFF8DB";
                break;
              case "Walk":
                color = "#C4FAF8";
                break;
              case "VirtualRide":
                color = "#85C5FF";
                break;
              case "Swim":
                color = "#C6A1CF";
                break;
              default:
                color = "#FFABAB";
                break;
            }
            return <Cell name={entry.type} key={entry.id} fill={color} />;
          })}
          {/* <LabelList dataKey='distanceString' position='top' fill='#000000' /> */}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StravaActivityChart;
