import React, {useState} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {StripePaymentRequest} from '../StripePaymentRequest';
import { TextField, Button } from "@material-ui/core";

import axios from 'axios';


export const StripePage = () => {

  const [checkoutId, setCheckoutId] = useState("");
	const [clientSecret, setClientSecret] = useState(null);
  


  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  const stripePromise = loadStripe("pk_test_KW0KHt0jIPsnAJHEjC5tiziP");

  const handleSubmit = () => {
    console.log('checkoutId',checkoutId);

    var config = {
      method: 'get',
      url: `https://qaapi.edrington.com/consumer/v1/macallan/en/gb/checkout/${checkoutId}/paymentservicedetails`,
      headers: { 
        'Ocp-Apim-Subscription-Key': '37ff05b938534559b29d82eb4f38117f'
      }
    };

    axios(config)
    .then(
      res => {
        alert('Received Payment Details')
        setClientSecret(res.data.secret);
      }
    )
    .catch(
      error => alert(error)
    )
  }

  return (
    <Elements stripe={stripePromise}>
      <h2>Mobile Wallet</h2>
      <TextField
				variant='outlined'
				label='CheckoutId'
				onChange={(e) => setCheckoutId(e.target.value)}
        style={{margin: '10px 5px'}}
				
				value={checkoutId}
			/>
			<Button
				variant='contained'
				color='primary'
        onClick={handleSubmit}
        style={{margin: '10px 5px'}}
			>
				Create Payment Button
			</Button>
      <StripePaymentRequest clientSecret={clientSecret} />
    </Elements>
  )
}
