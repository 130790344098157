import React from "react";
import SmallPaper from "../hoc/SmallPaper";
import { Typography, CircularProgress } from "@material-ui/core";
import { format, parse } from "date-fns";

export default ({ isLoaded, error, date, body }) => {
  let parsedDate = isLoaded ? parse(date, "yyyy-MM-dd", new Date()) : null;
  
  const headerName = 'Word of the Day';

	if (isLoaded && error) {
		return (
			<SmallPaper elevation={4}>
				<Typography variant='h6'>{headerName} - ERROR</Typography>
				<Typography variant='body1'>{error.response.data.message}</Typography>
			</SmallPaper>
		);
	}

	return (
		<SmallPaper elevation={4}>
			<Typography variant='h6'>{headerName}</Typography>

			{!isLoaded ? (
				<CircularProgress />
			) : (
				<>
					<Typography variant='subtitle1'>
						({format(parsedDate, "EEEE do MMMM")})
					</Typography>
					<Typography
						style={{
							fontStyle: "italic",
							fontWeight: "bold",
							marginTop: 10,
							textTransform: "capitalize",
						}}
						variant='h5'
					>
						{body.word}
					</Typography>
					<Typography
						style={{ fontStyle: "italic" }}
						variant='body1'
					>{`(${body.definitions[0].partOfSpeech})`}</Typography>
					<Typography
						style={{ fontStyle: "italic" }}
						variant='body2'
					>{`def: ${body.definitions[0].text}`}</Typography>
				</>
			)}
		</SmallPaper>
	);
};
