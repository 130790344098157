import React, { useContext } from "react";
import { Paper, makeStyles, useTheme } from "@material-ui/core";
import { AppContext } from "../context/AppContext";

const useStyles = makeStyles(theme => ({
  paper: {
    width: "90%",
    margin: "20px auto",
    textAlign: "center",
    padding: "10px",
    [theme.breakpoints.up('md')]: {
      width: '60%'
    }
  },
}));

const MediumPaper = (props) => {
  const classes = useStyles();
  const context = useContext(AppContext);
  const theme = useTheme();
  return (
    <Paper
      className={classes.paper}
      elevation={props.elevation}
      style={{...props.style, backgroundColor: !context.darkMode && props.style?.backgroundColor ? props.style.backgroundColor : theme.palette.background.default }}
    >
      {props.children}
    </Paper>
  );
};

export default MediumPaper;
