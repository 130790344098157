import React from 'react';
import {UserName} from '..';

export const AccountPage = () => {

  return (
    <>
      <UserName />
    </>
  )
}
