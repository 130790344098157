import React from 'react';
import { useLocalStorage } from '../common/useLocalStorage';

export const StravaContext = React.createContext({
  accessToken: '',
  setAccessToken: () => {},
  refreshToken: '',
  setRefreshToken: () => {},
  athleteId: '',
  setAthleteId: () => {}
});

export default ({children}) => {
  const [accessToken, setAccessToken] = useLocalStorage('st-a', '');
  const [refreshToken, setRefreshToken] = useLocalStorage('st-r', '');
  const [athleteId, setAthleteId] = useLocalStorage('st-id', '');

  const defaultContext = {
    accessToken,
    setAccessToken,
    refreshToken,
    setRefreshToken,
    athleteId,
    setAthleteId
  };

  return (
    <StravaContext.Provider value={defaultContext}>
      {children}
    </StravaContext.Provider>
  );
}