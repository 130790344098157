import { Button, TextField, Typography } from "@material-ui/core";
import React, {useRef} from "react";
import SmallPaper from "../hoc/SmallPaper";

const CalorieGoal = (props) => {
  const { goal, setGoal } = props;
  const goalRef = useRef(goal);  
  
  const handleSubmit = () => {
    setGoal(goalRef.current.value);
		goalRef.current.value = "";
	};

  const handleKeydown = (e) => {
		if (e.keyCode === 13) {
			handleSubmit();
		}
	};

  return (
    <SmallPaper elevation={3} style={{backgroundColor: '#f9f9f9'}}>
      <Typography variant='h4'>
					{goal} kcal
				</Typography>
      <TextField
        inputRef={goalRef}
        variant='outlined'
        label='Goal'
        type='number'
        fullWidth
        onKeyDown={handleKeydown}
        style={{ marginTop: 10, paddingBottom: 10 }}
      />
      <Button onClick={handleSubmit} variant='contained' color='primary'>Set New Goal</Button>
    </SmallPaper>
  )
}

export default CalorieGoal;
