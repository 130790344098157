import React from 'react';
import {Typography, Paper, makeStyles} from '@material-ui/core'
import {secondsToHms} from '../../helpers/TimeHelpers';


const useStyles = makeStyles({
  root: {
      margin: 15,
      textAlign: 'center',
      padding: '10px'
  }
});


export default ({name, time}) => {

  const classes = useStyles();

  return (
    <Paper className={classes.root}>    
      <Typography style={{paddingTop: 10}} variant='h6'>{name}</Typography>
      <Typography variant='body1'>{secondsToHms(time)} </Typography>
    </Paper>
  )
 
}