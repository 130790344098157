import React from "react";
import StravaLap from "./StravaLap";
import ThemedPaper from "../../hoc/ThemedPaper";

export default ({ laps }) => {
	return (
		<ThemedPaper
			elevation={3}
			style={{ marginTop: 10, backgroundColor: "lightblue" }}
		>
			<div
				style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
			>
				{laps.map((x) => {
					return (
						<StravaLap
							key={x.id}
							name={x.name}
							time={x.moving_time}
							distance={x.distance}
						/>
					);
				})}
			</div>
		</ThemedPaper>
	);
};
