import React from "react";
import SmallPaper from "../hoc/SmallPaper";
import { Typography, IconButton, Tooltip, CircularProgress, Divider } from "@material-ui/core";
import LoopRoundedIcon from "@material-ui/icons/LoopRounded";
import googleLogo from '../assets/google.png';
import wikiLogo from '../assets/wiki.png';

export const RandomQuoteDisplay = (props) => {
  const { isLoaded, error, text, author, refresh } = props;

  const googleLink = "https://www.google.co.uk/search?q=" + author;
  const wikiLink = "https://en.wikipedia.org/wiki/" + author;

	return (
		<SmallPaper elevation={5}>
			<Typography variant='h6'>Random Quote</Typography>
      {(!isLoaded) 
        ? <CircularProgress /> 
        : <>
            <Tooltip title='New Quote' placement='right'>
            <IconButton color='primary' component='span' onClick={refresh}>
              <LoopRoundedIcon />
            </IconButton>
            </Tooltip>
            <Typography variant='h6'>{author ?? "Unknown"}</Typography>
            <Typography variant='body1'>{error ? error : text}</Typography>
            {(author) &&
              <>
              <Divider light style={{margin: '10px 0px'}} />
              <Tooltip title="Search Author on Google">
                <a target="_blank" rel="noopener noreferrer" href={googleLink}>
                  <img src={googleLogo} alt="google" style={{height:30,width:30, marginRight: 20}} />
                </a>
              </Tooltip>
              <Tooltip title="Learn more on Wikipedia">
                <a href={wikiLink} target="_blank" rel="noopener noreferrer">
                  <img src={wikiLogo} alt="google" style={{height:30,width:30}} />
                </a>       
              </Tooltip>
            </>
            }
          </>
      }			
		</SmallPaper>
	);
};
