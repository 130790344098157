import React from "react";
import SmallPaper from "../hoc/SmallPaper";
import { Typography, IconButton, Tooltip, CircularProgress } from "@material-ui/core";
import LoopRoundedIcon from "@material-ui/icons/LoopRounded";

export const CatFactDisplay = (props) => {
  const { isLoaded, error, fact } = props;

	return (
		<SmallPaper elevation={5}>
			<Typography variant='h6'>Random Cat Fact</Typography>
      {(!isLoaded) 
        ? <CircularProgress /> 
        : <>
            <Tooltip title='New Cat Fact' placement='right'>
            <IconButton color='primary' component='span' onClick={props.refresh}>
              <LoopRoundedIcon />
            </IconButton>
            </Tooltip>
            <Typography variant='body1'>{error ? error.message : fact}</Typography>
          </>
      }			
		</SmallPaper>
	);
};
