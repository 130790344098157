import React, { useContext, useEffect, useState } from "react";
import { RootContext } from "../../context";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import SmallPaper from "../../hoc/SmallPaper";
import axios from "axios";

export const LoginPage = ({ history }) => {
	const rootCtx = useContext(RootContext);
	const [state, setState] = useState("");

	useEffect(() => {
		if (rootCtx.authenticated) {
			history.push("/");
		}
	}, [rootCtx, history]);

	const handleSubmit = () => {

    const data = {
      auth: state
    }
    
    axios.post('https://functions.neishcode.com/api/auth', data)
      .then(
        res => {
          rootCtx.setAuthBody(res.data.data);
          rootCtx.setAuthenticated(true);
        }
      )
      .catch(
        err => {

          console.log(err);

          if (err.response !== null && err.response !== undefined && err.response.status === 401){
            alert('Unauthorised Sucka!');
          }
          else {
            alert('Something has gone wrong!');
          }
        }
      )

	};

	const handleKeydown = (e) => {
		if (e.keyCode === 13) {
			handleSubmit();
		}
	};

	return (
		<SmallPaper elevation={3}>
			<TextField
				onChange={(e) => setState(e.target.value)}
				variant='outlined'
        label="What's the magic word?"
        type='password'
        fullWidth
        onKeyDown={handleKeydown}
        style={{paddingBottom: 10}}
			/>
			<Button variant='contained' color='primary' onClick={handleSubmit}>
				Login
			</Button>
		</SmallPaper>
	);
};
