import React from 'react';
import {Typography, Paper, makeStyles, IconButton} from '@material-ui/core'

const useStyles = makeStyles({
  root: {
      margin: 15,
      textAlign: 'center',
      padding: '10px'
  }
});

export default ({name, url, distance}) => {

  const classes = useStyles();

  return (
    <IconButton onClick={() => window.open(url, "_blank")}>
      <Paper className={classes.root}>    
        <Typography style={{paddingTop: 10}} variant='h6'>{name}</Typography>
        <Typography variant='body1'>{(distance/1000).toFixed(2)} km </Typography>
      </Paper>
    </IconButton>
  )
 
}