import React from 'react';
import queryString from 'query-string';
import { useContext } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { StravaContext } from '../../context';

export default (props) => {

  const context = useContext(StravaContext);

  useEffect(() => {

    const parsed = queryString.parse(props.location.search);

    axios.post(`https://www.strava.com/oauth/token?client_id=14806&client_secret=1eb6fb15579a1a0b83b5d6daac05d9877a94c497&code=${parsed.code}&scope=${parsed.scope}`)
      .then(
        res => {
          context.setAccessToken(res.data.access_token);
          context.setRefreshToken(res.data.refresh_token);
          context.setAthleteId(res.data.athlete.id);
          props.history.push('/strava');
        }
      )
      .catch(
        err => {
          console.log('Error getting access token for Strava - Redirecting', err.response);
          props.history.push('/auth/strava');
        }
      )      
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
    </>
  )
}