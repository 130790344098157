import React from "react";
import { useContext } from "react";
import { StravaContext } from "../../context";
import { useEffect, useState } from "react";
import { makeStyles, Button, CircularProgress, AppBar, Tabs, Tab } from "@material-ui/core";
import StravaService from '../service/StravaService';
import BestEfforts from '../components/StravaActivityBestEfforts';
import Laps from '../components/StravaLaps';
import Splits from '../components/StravaSplits';
import Segments from '../components/StravaActivitySegments';
import StravaActivityText from "../components/StravaActivityText";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flexWrap: "wrap"
  },
  text:{    
    margin: '20px 0',
    textAlign: 'center'
  },
  stravaButton: {
    backgroundColor: "#fc4c02", 
    color: "white", 
    marginBottom: 20
  }
}));

export const StravaActivityPage = ({history, match}) => {
  const context = useContext(StravaContext);
  const [state, setState] = useState({isLoaded: false, error: null, activity: null, page: 1});

  const activityId = match.params.activityId;
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    StravaService.getActivity(context.accessToken, activityId)
      .then(
        res => {
          console.log('activity', res.data);
          setState(prevState => ({...prevState, isLoaded: true, activity: res.data}));
        }          
      )
      .catch(_ => {
        alert('Error getting Activity');
      })   

    }, [activityId, context.accessToken]);

    
  if (!state.isLoaded) {
    return (
      <>
        <CircularProgress />
      </>
    )
  }

  return (
    <>
      <div className={classes.root}>
        <Button variant='contained' color='primary' onClick={history.goBack}>Back</Button>
        <div className={classes.text}>
          <StravaActivityText activity={state.activity} />
        </div> 
        <Button
            variant='contained'
            className={classes.stravaButton}
            onClick={() =>
              window.open(`https://www.strava.com/activities/${activityId}`)
            }
          >
            View on Strava
          </Button>
          <div style={{minWidth: '100%'}}>
          <AppBar position="static">
            <Tabs value={value} onChange={handleChange} aria-label="activity tabs" centered variant="fullWidth">              
              <Tab label="Laps"  />
              <Tab label="Best Efforts" disabled={!state.activity.best_efforts} />
              <Tab label="Splits (km)" disabled={!state.activity.splits_metric} />
              <Tab label="Splits (mile)" disabled={!state.activity.splits_standard} />
              <Tab label="Segment PRs" disabled={state.activity.segment_efforts.length === 0} />
            </Tabs>
          </AppBar>  
          <TabPanel value={value} index={0}>
            <Laps laps={state.activity.laps} />  
          </TabPanel>
          <TabPanel value={value} index={1}>
            {state.activity.best_efforts && <BestEfforts efforts={state.activity.best_efforts} />}
          </TabPanel>            
          <TabPanel value={value} index={2}>
            {state.activity.splits_metric && <Splits splits={state.activity.splits_metric} measure="km" />}
          </TabPanel> 
          <TabPanel value={value} index={3}>
            {state.activity.splits_standard && <Splits splits={state.activity.splits_standard} measure="km" />}
          </TabPanel> 
          <TabPanel value={value} index={4}>
            {state.activity.segment_efforts && <Segments segments={state.activity.segment_efforts} />}
          </TabPanel> 
          </div>
      </div>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
