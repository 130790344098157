import React, { useContext } from 'react';
import { AppBar, Toolbar, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {AppContext} from '../context/';
import NavDrawer from './NavDrawer';
import BrightnessMediumIcon from '@material-ui/icons/BrightnessMedium';
import LoginLink from './LoginLink';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1,
    },
    darkModeToggle: {
        alignItems: 'center'
    }
}));

const NavBar = () => {     

    const classes = useStyles();
    const context = useContext(AppContext);

    return (
        <div className={classes.root}>
            <AppBar position="static" color='primary'>
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={context.toggleDrawer}>
                        <MenuIcon/>
                    </IconButton>   
                    <div className={classes.title}>
                        <Typography variant='h6' style={{fontFamily: 'Anton, sans-serif'}}>
                        <Link to='/' style={{ color:'white', textDecoration: 'none'}}>
                            React Playground
                        </Link>
                        </Typography>             
                    </div> 
                    <Tooltip title={context.darkMode ? 'Light Mode' : 'Dark Mode'} >
                        <IconButton className={classes.darkModeToggle} onClick={context.toggleDarkMode}>
                            <BrightnessMediumIcon style={context.darkMode ? {fill: 'white'} : {}}/>
                        </IconButton>
                    </Tooltip>    
                    <LoginLink />
                </Toolbar>
            </AppBar>
            <NavDrawer />
        </div>

    )
}


export default NavBar;