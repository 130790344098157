import React from "react";
import { useContext } from "react";
import { RootContext } from "../context";
import Button from "@material-ui/core/Button";
import { Link, withRouter } from "react-router-dom";

const LoginLink = ({ history }) => {
	const context = useContext(RootContext);

	const logoutHandler = () => {
		context.setAuthenticated(false);
		context.setAuthBody(null);

		history.push("/");
	};

	if (context.authenticated) {
		return (
			<Button color='inherit' onClick={logoutHandler}>
				Logout{context.authBody !== null ? ` ${context.authBody.username}` : ""}
			</Button>
		);
	} else {
		return (
			<Button component={Link} to='/login' color='inherit'>
				Login
			</Button>
		);
	}
};

export default withRouter(LoginLink);
