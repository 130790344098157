import React from "react";
import { Typography } from "@material-ui/core";
import { format } from "date-fns";
import SmallPaper from "../hoc/SmallPaper";

export const TimerDisplay = ({date}) => (
	<SmallPaper elevation={5}>
		<Typography paragraph variant='h6'>{format(date, "EEEE do MMMM")}</Typography>
		<Typography variant='body1'>{date.toLocaleTimeString()}</Typography>
	</SmallPaper>
);
