import React, { useState, useEffect } from "react";
import { TextField, Button, makeStyles, Typography } from "@material-ui/core";
import PostcodeService from "../services/PostcodeService";
import SmallPaper from "../hoc/SmallPaper";
import {AppContext} from "../context/AppContext";

const useStyles = makeStyles({
	button: {
		margin: "10px 5px",
	},
	savedPostcode: {
		padding: "15px",
		fontSize: "1.5em",
		display: "block",
	},
});

export const PostcodeSearch = (props) => {
	const classes = useStyles();
	const context = React.useContext(AppContext);

	const [collapsed, setCollapsed] = useState(false);
	const [postcode, setPostcode] = useState("");
	const [error, setError] = useState({ isError: false, errorMessage: "" });

	useEffect(() => {
		let savedPostcode = context.postcode;
		if (
			savedPostcode !== "" &&
			savedPostcode !== undefined &&
			savedPostcode !== null
		) {
			setPostcode(savedPostcode);
			setCollapsed(true);
		}
	}, [context.postcode]);

	const onChange = (event) => {
		setPostcode(event.target.value);
	};

	const resetForm = () => {
		setError({ isError: false, errorMessage: "" });
		setPostcode("");
	};

	const handleSubmit = () => {
		setError({ isError: false, errorMessage: "" });

		if (postcode === "") {
			setError({ isError: true, errorMessage: "Please enter a postcode" });
			return;
		}

		PostcodeService.SearchPostcode(postcode)
			.then((d) => {
				context.updatePostcode(postcode.toLocaleUpperCase());
				setCollapsed(true);
			})
			.catch((e) =>
				setError({
					isError: true,
					errorMessage: "Error searching for this postcode!",
				})
			);
	};

	const handleKeydown = (e) => {
		if (e.keyCode === 13) {
			handleSubmit();
		}
	};

	const fullSearchElements = (
		<>
			<TextField
				className={classes.input}
				variant='outlined'
				label='UK Postcode'
				onKeyDown={handleKeydown}
				onChange={onChange}
				fullWidth
				value={postcode}
				error={error.isError}
				helperText={error.errorMessage}
			/>
			<Button
				className={classes.button}
				variant='contained'
				color='primary'
				onClick={handleSubmit}
			>
				Search
			</Button>
			<Button
				className={classes.button}
				variant='contained'
				color='secondary'
				onClick={resetForm}
			>
				Clear
			</Button>
		</>
	);

	const collapsedElements = (
		<>
			<Typography variant='h6' className={classes.savedPostcode}>{postcode}</Typography>
			<Button
				className={classes.button}
				variant='contained'
				color='primary'
				onClick={() => setCollapsed(false)}
			>
				Change Postcode
			</Button>
		</>
	);

	return (
		<SmallPaper className={classes.paper} elevation={3}>
			{collapsed ? collapsedElements : fullSearchElements}
		</SmallPaper>
	);
};
