import React from 'react';
import {formatDateWithTime} from '../../helpers/DateHelpers';
import {secondsToHms} from '../../helpers/TimeHelpers';
import {splitOnCamelCase} from '../../helpers/StringHelpers';
import {Divider, Typography} from '@material-ui/core';

export default ({activity, display = "lg"}) => {

  const measurement = "km";

  const distanceKm = (activity.distance/1000).toFixed(2);

  const distanceFormatted = `${distanceKm}${measurement} in `;
  const typeFormatted = splitOnCamelCase(activity.type); 

  const paceInSeconds = (activity.moving_time)/distanceKm;

  if (display === 'sm'){
    return (
      <>
        <Typography variant='h6'>{typeFormatted}</Typography>
        <Typography variant='body1'>{activity.name}</Typography>
        <Typography variant='body1'>({activity.distance > 0 ? distanceFormatted : null}{secondsToHms(activity.moving_time)})</Typography>
        <Typography variant='body1'>{formatDateWithTime(activity.start_date_local)}</Typography>
      </>
    )
  }

  return (
    <>      
      <Typography variant='h5'>{activity.name}</Typography>
      <Divider style={{margin:'10px 0'}} />
      <Typography variant='h5'>{typeFormatted}</Typography>
      <Typography variant='h6'>{activity.distance > 0 ? distanceFormatted : null}{secondsToHms(activity.moving_time, false)}</Typography>
      <Typography variant='body1'>{formatDateWithTime(activity.start_date_local)}</Typography>
      <Divider style={{margin:'10px 0'}} />
      <Typography variant='body1'><strong>Gear:</strong> {activity.gear.name}</Typography>
      <Typography variant='body1'><strong>Pace:</strong> {secondsToHms(paceInSeconds, true)} per {measurement}</Typography>
      <Typography variant='body1'><strong>Calories:</strong> {activity.calories} kcal</Typography>
      <Typography variant='body1'><strong>Elevation Gain:</strong> {activity.total_elevation_gain}m</Typography>
      <Typography variant='body1'><strong>Suffer Score:</strong> {activity.suffer_score}</Typography>
    </>
  )

}



