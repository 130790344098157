import React from 'react';
import { useContext } from 'react';
import { useEffect, useState } from 'react';
import {CircularProgress, Typography, Button, Paper, makeStyles} from '@material-ui/core'
import { withRouter } from 'react-router-dom';
import StravaService from '../service/StravaService';
import StravaActivityChart from './StravaActivityChart';
import {formatDate} from '../../helpers/DateHelpers';
import StravaActivityText from '../components/StravaActivityText';
import ThemedPaper from '../../hoc/ThemedPaper';
import { StravaContext } from '../../context';
import StravaThisWeekStats from '../components/StravaThisWeekStats';

const useStyles = makeStyles((theme) => ({
  root: {
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
   flexWrap: 'wrap',
   margin: '20px 0'

  },
  activities: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  activity: {
    width: '300px',
    margin: 15,
    textAlign: 'center',
    padding: '10px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  Run: {
    backgroundColor: theme.palette.type === 'dark' ? 'inherit' : '#AFF8DB'    
  },
  Ride: {
    backgroundColor: theme.palette.type === 'dark' ? 'inherit' : '#85E3FF'
  }, 
  VirtualRide: {
    backgroundColor: theme.palette.type === 'dark' ? 'inherit' : '#85C5FF'
  }, 
  Swim: {
    backgroundColor: theme.palette.type === 'dark' ? 'inherit' : '#C6A1CF'
  },
  Walk: {
    backgroundColor: theme.palette.type === 'dark' ? 'inherit' : '#C4FAF8'
  },
  WeightTraining: {
    backgroundColor: theme.palette.type === 'dark' ? 'inherit' : '#FFABAB'
  },
  Workout: {
    backgroundColor: theme.palette.type === 'dark' ? 'inherit' : '#FFDAC1'
  }
}));

const StravaActivities = ({history}) => {

  const context = useContext(StravaContext);
  const [state, setState] = useState({isLoaded: false, error: null, data: [], page: 1});

  const classes = useStyles();

  useEffect(() => {   
    StravaService.getActivities(context.accessToken, state.page)
      .then (
        res => {
          setState(prevState => ({...state, isLoaded: true, data: [...prevState.data, ...res.data]}));
        }
      )
      .catch(
        err => {
          console.log('Error getting Stats', err.response);
          history.push('/auth/strava');
        }
      )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.page]); 

  const loadMoreHandler = () => {
    setState(prevState => ({...state, page: prevState.page + 1}));
  }

  if (!state.isLoaded) {
    return (
      <>
        <CircularProgress />
      </>
    )
  }
    // TODO: Move activity display to own file

  return (
    <ThemedPaper className={classes.root}>
      <Typography paragraph variant='h5'>Activities</Typography>
      <StravaThisWeekStats activities={state.data} />
      <Typography paragraph variant='body1'>Between {formatDate(state.data[state.data.length - 1].start_date_local)} and {formatDate(state.data[0].start_date_local)}</Typography>
      <StravaActivityChart data={state.data} />
      <div className={classes.activities}>
        {state.data.map(x => {
          const className = [classes.activity, classes[x.type]];
          return <Paper key={x.id} className={className.join(' ')} onClick={() => history.push(`/strava/activity/${x.id}`)}>
            <StravaActivityText display='sm' activity={x} />
          </Paper>
        })}
      </div>   
      <Button variant='contained' color='primary' style={{marginBottom: 20}} onClick={loadMoreHandler}>Load More</Button>
    </ThemedPaper>
  )
}

export default withRouter(StravaActivities);