import React, { useState, useEffect, useCallback } from "react";
import { RandomQuoteDisplay } from "../components/RandomQuoteDisplay";
import axios from "axios";

const RandomQuote = () => {
	const [state, setState] = useState({
		isLoaded: false,
		error: null,
		quotes: [],
		text: "",
		author: ""
	});

	const getQuote = useCallback(()  => {

		if (state.quotes.length === 0) {
			return;
		}

    let rand = Math.floor(Math.random() * state.quotes.length);
    setState(prevState => ({...prevState, author: state.quotes[rand].author, text: state.quotes[rand].text}));
  }, [state.quotes]);

	useEffect(() => {
		axios
			.get("https://type.fit/api/quotes")
			.then((res) => {
				setState(prevState => ({
					...prevState,
					isLoaded: true,
					quotes: res.data,
				}));
			})
			.catch((error) =>
				setState((prevState) => ({ ...prevState, error, isLoaded: true }))
			);
	}, []);

	useEffect(() => {
		getQuote();
	}, [state.quotes, getQuote])




	return <RandomQuoteDisplay {...state} refresh={getQuote} />;
};

export default RandomQuote;
