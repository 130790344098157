import React from "react";
import StravaSplit from "./StravaSplit";
import ThemedPaper from "../../hoc/ThemedPaper";

export default ({ splits, measure }) => {
	const fullSplits = splits.slice(0, -1);
	const finalSplit = splits.slice(-1)[0];

	return (
		<ThemedPaper
			elevation={3}
			style={{ marginTop: 10, backgroundColor: "#2F79CE" }}
		>
			{/* <Typography variant='h6' style={{ textAlign: "center" }}>
				Splits: {measure.toUpperCase()}
			</Typography> */}
			<div
				style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
			>
				{fullSplits.map((x) => {
					return (
						<StravaSplit
							key={x.split}
							split={x.split}
							time={x.moving_time}
							distance={x.distance}
							measure={measure}
						/>
					);
				})}
				<StravaSplit
					split={finalSplit.split}
					time={finalSplit.moving_time}
					distance={finalSplit.distance}
					measure={measure}
				/>
			</div>
		</ThemedPaper>
	);
};
