import React from "react";
import { useContext } from "react";
import { StravaContext } from "../../context";
import { useEffect, useState } from "react";
import StravaStats from "./StravaStats";
import StravaActivities from "./StravaActivities";
import {
  makeStyles,
  Button,
  Tooltip,
  Typography
} from "@material-ui/core";
import stravaLight from "../../assets/stravaLight.png";
import StravaGearStats from "../components/StravaGearStats";
import StravaService from '../service/StravaService';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  gearStats: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: 20
  },
  poweredByStrava: {
    width: 120,
    height: 25,
    cursor: "pointer",
    marginTop: 15
  },
  profilePhoto: {
    width: 130,
    height: 130,
    borderRadius: "50%",
    cursor: "pointer"
  },
  stravaButton: {
    backgroundColor: "#fc4c02", 
    color: "white", 
    margin: '10px 0'
  }
}));

export const StravaPage = (props) => {
  const context = useContext(StravaContext);
  const [athlete, setAthlete] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    if (context.refreshToken !== ""){
      StravaService.updateRefreshToken(context.refreshToken)
        .then(res => {
          context.setAccessToken(res.data.access_token);

          StravaService.getAthlete(res.data.access_token)
            .then(
              res => setAthlete(res.data)       
            )
        })
        .catch(_ => {
          context.setAccessToken("");
        })
    }
  }, [context])

  if (context.accessToken === "") {
    return (
      <>
        <img
          src={stravaLight}
          alt='strava'
          className={classes.poweredByStrava}
        />
        <Button
          variant='contained'
          className={classes.stravaButton}
          onClick={() => props.history.push("auth/strava")}
        >
          Link Account
        </Button>
      </>
    );
  }

  const unlinkAccountHandler = () => {
    context.setAthleteId('');
    context.setRefreshToken('');
    context.setAccessToken('');
    setAthlete(null);

  }

  return (
    <>
      {athlete && (
        <div className={classes.root}>          
          <Tooltip title='View your profile' placement='right'>
            <img
              src={athlete.profile}
              alt='profile'
              className={classes.profilePhoto}
              onClick={() =>
                window.open(`https://www.strava.com/athletes/${athlete.id}`)
              }
            />
          </Tooltip>
          <Typography variant='h6'>{athlete.firstname} {athlete.lastname}</Typography>
          <Tooltip title='Go to Strava' placement='right'>
            <img
              src={stravaLight}
              alt='strava'
              className={classes.poweredByStrava}
              onClick={() => window.open("https://www.strava.com")}
            />
          </Tooltip>
          <StravaStats />          
          <StravaGearStats title='Bikes' gear={athlete.bikes} />
          <StravaGearStats title='Shoes' gear={athlete.shoes} />
          <StravaActivities />
          <Button
            variant='contained'
            className={classes.stravaButton}
            onClick={unlinkAccountHandler}
          >
            Unlink Account
          </Button>
        </div>
      )}
    </>
  );
};
