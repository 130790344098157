import React from "react";
import StravaActivityBestEffort from "./StravaActivityBestEffort";
import ThemedPaper from "../../hoc/ThemedPaper";

export default ({ efforts }) => {
  
  return (
		<ThemedPaper
			elevation={3}
			style={{ marginTop: 10, backgroundColor: "lightgrey" }}
		>
			<div
				style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
			>
				{efforts.map((x) => {
					return (
						<StravaActivityBestEffort
							key={x.id}
							name={x.name}
							time={x.moving_time}
						/>
					);
				})}
			</div>
		</ThemedPaper>
	);
};
