import React from 'react';
import Timer from '../../containers/Timer';
import homerDonut from '../../assets/homer-donut.png';
import CatFact from '../../containers/CatFact';
import WordoftheDay from '../../containers/WordoftheDay';
import RandomQuote from '../../containers/RandomQuote';

export const HomePage = () => {
  return (
    <>
      <img src={homerDonut} alt="unicorn" style={{height:300,width:300, margin:'10px auto'}} />
      <Timer />
      <WordoftheDay />
      <RandomQuote />
      <CatFact />
    </>
  )
}
