import React, {useContext} from 'react';
import { Paper, useTheme } from '@material-ui/core';
import {AppContext} from '../context/AppContext';


const ThemedPaper = (props) => {

    const context = useContext(AppContext);
    const theme = useTheme();

    return (
        <Paper 
        style={{...props.style, backgroundColor: !context.darkMode && props.style?.backgroundColor ? props.style.backgroundColor : theme.palette.background.default }}
        elevation={props.elevation}
        className={props.className}>
            {props.children}
        </Paper>
    )
}

export default ThemedPaper