import React, {useEffect, useState} from 'react';
import {useLocalStorage} from '../common/useLocalStorage';
import WoDDisplay from '../components/WordoftheDayDisplay';
import { format } from "date-fns";
import axios from 'axios';
export default () => {

  const [wordDate, setWordDate] = useLocalStorage('wordDate', null);
  const [wordBody, setWordBody] = useLocalStorage('wordBody', null);
  
  const [state, setState] = useState({
		isLoaded: false,
		error: null
	});

  useEffect(() => {
    let today = new Date();
    today = format(today, 'yyyy-MM-dd');

    const callApi = (searchDate) => {
      axios.get(`https://api.wordnik.com/v4/words.json/wordOfTheDay?date=${searchDate}&api_key=w19xr6bvnrkzx3it533robf4ivkhr739q7trtf8oj1eyz5d7p`)
      .then(
        res => {  
          setWordDate(searchDate);
          setWordBody(res.data);
          setState({ ...state, isLoaded: true })
        }
      )
      .catch(
        error => setState({ ...state, error, isLoaded: true })
      )
    }
    
    if (today !== wordDate || wordBody === null){
      callApi(today);
    } else {
      setState({...state, isLoaded: true});
    }

    // eslint-disable-next-line
  }, [])

  

  return (
   <WoDDisplay date={wordDate} body={wordBody} {...state} />
  )

}