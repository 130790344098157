import React from 'react';
import { useContext } from 'react';
import { useEffect, useState } from 'react';
import {CircularProgress, Typography, Button, makeStyles} from '@material-ui/core'
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import StravaStatsDisplay from '../components/StravaStatsDisplay';
import ThemedPaper from '../../hoc/ThemedPaper';
import { StravaContext } from '../../context';

const useStyles = makeStyles((theme) => ({
  root: {
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
   flexWrap: 'wrap',
   margin: 20
  },
  stats: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  }
}));

const StravaStats = ({history}) => {

  const context = useContext(StravaContext);
  const [state, setState] = useState({isLoaded: false, error: null, data: {}, measure: 'km'});

  const classes = useStyles();

  useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${context.accessToken}` }
    };

    axios.get(`https://www.strava.com/api/v3/athletes/${context.athleteId}/stats`, config)
      .then (
        res => {
          res.data.recent_run_totals.distance = res.data.recent_run_totals.distance/1000;
          res.data.recent_ride_totals.distance = res.data.recent_ride_totals.distance/1000;          
          res.data.all_run_totals.distance = res.data.all_run_totals.distance/1000;
          res.data.all_ride_totals.distance = res.data.all_ride_totals.distance/1000;
          res.data.ytd_run_totals.distance = res.data.ytd_run_totals.distance/1000;
          res.data.ytd_ride_totals.distance = res.data.ytd_ride_totals.distance/1000;

          setState(prevState => ({...prevState, isLoaded: true, data: res.data }));
        }
      )
      .catch(
        err => {
          console.log('Error getting Stats', err.response);
          history.push('/auth/strava');
        }
      )

  },[context.athleteId, context.accessToken, history])

  const toggleMeasurementHandler = () => {
    // Deep clone for nested objects
    let clonedData = JSON.parse(JSON.stringify(state.data));
    let toggledMeasure = state.measure === 'km' ?  'miles' : 'km';

    if (state.measure === 'km') {
      // Switch to miles
      const KMTOMILES = 0.621371;
      clonedData.recent_run_totals.distance = clonedData.recent_run_totals.distance * KMTOMILES; 
      clonedData.recent_ride_totals.distance = clonedData.recent_ride_totals.distance * KMTOMILES;

      clonedData.all_run_totals.distance = clonedData.all_run_totals.distance * KMTOMILES;
      clonedData.all_ride_totals.distance = clonedData.all_ride_totals.distance * KMTOMILES;      

      clonedData.ytd_run_totals.distance = clonedData.ytd_run_totals.distance * KMTOMILES;
      clonedData.ytd_ride_totals.distance = clonedData.ytd_ride_totals.distance * KMTOMILES;
    } else {
      // Switch to km
      const MILESTOKM = 1.60934;
      clonedData.recent_run_totals.distance = clonedData.recent_run_totals.distance * MILESTOKM; 
      clonedData.recent_ride_totals.distance = clonedData.recent_ride_totals.distance * MILESTOKM;

      clonedData.all_run_totals.distance = clonedData.all_run_totals.distance * MILESTOKM;
      clonedData.all_ride_totals.distance = clonedData.all_ride_totals.distance * MILESTOKM;

      clonedData.ytd_run_totals.distance = clonedData.ytd_run_totals.distance * MILESTOKM;
      clonedData.ytd_ride_totals.distance = clonedData.ytd_ride_totals.distance * MILESTOKM;
    }

    setState({...state, measure: toggledMeasure, data: clonedData })
  }


  if (!state.isLoaded) {
    return (
      <>
        <CircularProgress />
      </>
    )
  }
  return (
    <ThemedPaper className={classes.root}>
      <Typography paragraph variant='h5'>Run/Ride Stats</Typography>
      <Button color='primary' variant='contained' onClick={toggleMeasurementHandler}>Toggle KM/Miles</Button>
      <div className={classes.stats}>
        <StravaStatsDisplay run={state.data.recent_run_totals} ride={state.data.recent_ride_totals} measure={state.measure} title="Last 28 Days" />
        <StravaStatsDisplay run={state.data.ytd_run_totals} ride={state.data.ytd_ride_totals} measure={state.measure} title="Year to Date" />
        <StravaStatsDisplay run={state.data.all_run_totals} ride={state.data.all_ride_totals} measure={state.measure} title="All Time" />
      </div>     
    </ThemedPaper>
  )
}

export default withRouter(StravaStats);